import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader
import hu from 'vuetify/src/locale/hu'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { hu },
    current: 'hu',
  },
  theme: {
    themes: {
      light: {
        primary: "#9DBC9A",
        secondary: "#396136",
        accent: "#FFAE4F",
        info:  "#DEDEDE",
      },
    },
  },
  // icons: {
  //   iconfont: "fa",
  // },
});
