// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBIybGuGbsonIjgce2lM4EiKug_gNu-4lo",
  authDomain: "klarisebi.firebaseapp.com",
  projectId: "klarisebi",
  storageBucket: "klarisebi.appspot.com",
  messagingSenderId: "957431948493",
  appId: "1:957431948493:web:814bd3463b6b21b1e0f584",
  measurementId: "G-LVG7LZF6YW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
let analytics = null;
const db = getFirestore(app);
if (process.env.NODE_ENV === "production") {
  analytics = getAnalytics(app);
  console.log("Analytics initialized");
} else {
  connectFirestoreEmulator(db, "localhost", 8081);
  console.warn("Not production build");
}

export { app, analytics, db, auth };
