import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";
import "@/styles/index.scss";
import "./redirect";
import "./firebase";
import store from "./store";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";

Vue.use(VueMeta);

Vue.config.productionTip = false;

onAuthStateChanged(auth, (user) => {
  if (user) {
    store.commit('setUser', user)
  } else {
    store.commit('setUser', null)
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  mounted() {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");
