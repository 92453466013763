
import { Vue, Component, Prop } from "vue-property-decorator";

type Item = {
  image?: string;
  text?: string;
  hideable?: boolean;
};

@Component
export default class HexaGrid extends Vue {
  @Prop({ type: Array, required: true }) items!: Item[];

  get filteredItems(): Item[] {
    let overFlow =
      (this.items.length % (2 * this.gridSize - 1)) % this.gridSize;
    const numHideable = this.items.filter(({ hideable }) => hideable).length;
    return this.items.filter((item: Item) => {
      if (overFlow > numHideable) return true;
      if (overFlow > 0 && item.hideable) {
        overFlow--;
        return false;
      }
      return true;
    });
  }

  get gridSize() {
    if (this.$vuetify.breakpoint.xs) return 2;
    return 4;
  }

  getWrapperStyle(n: number) {
    return {
      "grid-template-columns": `repeat(${n * 2}, 1fr)`,
    };
  }

  getItemStyle(n: number, idx: number) {
    const batchSize = 2 * n - 1;
    const evenRow = idx % batchSize <= n - 1;
    const rowIdx = Math.floor(idx / batchSize) * 2 + (evenRow ? 0 : 1);

    const gridRow = `${rowIdx * 2 + 1} / span 3`;
    const gridCol = `${
      evenRow ? (idx % batchSize) * 2 + 1 : ((idx % batchSize) - n + 1) * 2
    } / span 2`;
    return {
      "grid-row": gridRow,
      "grid-column": gridCol,
    };
  }
}
