import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    meta: {
      collapseNav: true,
    },
    component: HomeView,
  },
  {
    path: "/visszajelzes",
    name: "RSVP",
    component: () =>
      import(/* webpackChunkName: "rsvp" */ "../views/RSVPView.vue"),
  },
  {
    path: "/naszajandek",
    name: "Gifts",
    component: () =>
      import(/* webpackChunkName: "gifts" */ "../views/GiftsView.vue"),
  },
  {
    path: "/visszajelzes/:responseIdHash",
    name: "Response",
    component: () =>
      import(/* webpackChunkName: "response" */ "../views/ResponseView.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/AdminView.vue"),
    children: [
      {
        path: '/',
        redirect: {name:'AdminResponses'}
      },
      {
        path: 'visszajelzes',
        name: 'AdminResponses',
        component: () =>
          import(/* webpackChunkName: "admin-responses" */ "../views/AdminResponsesView.vue"),
      },
      {
        path: 'naszajandek',
        name: 'AdminGifts',
        component: () =>
          import(/* webpackChunkName: "admin-gifts" */ "../views/AdminGiftsView.vue"),
      },
      {
        path: "visszajelzes/:responseId",
        name: "AdminResponse",
        component: () =>
          import(/* webpackChunkName: "admin-response" */ "../views/ResponseView.vue"),
      },
    ]
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/NotFoundView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { ...savedPosition, behavior: "smooth" };
    }
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

export default router;
