
import { Vue, Component, Watch } from "vue-property-decorator";
import NotFoundView from "@/views/NotFoundView.vue";
import { jarallax } from "jarallax";

@Component({
  metaInfo: {
    title: "Klári & Sebi",
  },
  components: {
    NotFoundView,
  },
})
export default class App extends Vue {
  mounted() {
    this.jarallaxInit();
  }

  @Watch("$route.path")
  jarallaxInit() {
    this.$nextTick(() => {
      console.log("jarallax init");
      jarallax(document.querySelectorAll(".jarallax"), {
        speed: 0.9,
      });
    });
  }

  get validRoute(): boolean {
    return !!this.$route.name || this.$route.path === "/";
  }

  get collapseNav(): boolean {
    return this.$route.meta && this.$route.meta.collapseNav;
  }
}
