import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

/* eslint-disable @typescript-eslint/no-explicit-any */
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state: any) => ({ hashes: state.hashes }),
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    email: null as string | null,
    hashes: [] as Array<{ hash: string; date: Date }>,
    user: null as any,
  },
  mutations: {
    saveEmail(state: any, email: string) {
      state.email = email;
    },
    saveHash(state: any, hash: string) {
      state.hashes.push({ hash, date: new Date() });
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  plugins: [vuexLocal.plugin],
});
