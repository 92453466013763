
import { Vue, Component } from "vue-property-decorator";
import { DateTime } from "luxon";

@Component
export default class CountDown extends Vue {
  now = DateTime.now();
  weddingDay = DateTime.fromObject({ year: 2023, month: 7, day: 1 });
  interval = null as number | null;

  mounted() {
    window.clearInterval(this.interval as number);
    this.interval = window.setInterval(() => (this.now = DateTime.now()), 1000);
  }

  beforeDestroy() {
    window.clearInterval(this.interval as number);
  }

  get duration(): Record<string, string> {
    const d = this.weddingDay.diff(this.now);
    const parts = d.toFormat("dd hh mm ss").split(" ");

    return {
      nap: parts[0],
      óra: parts[1],
      perc: parts[2],
      másodperc: parts[3],
    };
  }
}
