
import CountDown from "@/components/CountDown.vue";
import HexaGrid from "@/components/HexaGrid.vue";
import HexaDivider from "@/components/HexaDivider.vue";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    CountDown,
    HexaGrid,
    HexaDivider,
  },
})
export default class HomeView extends Vue {
  timeline = {
    "15:30": {
      title: "Nászmise",
      text: `
        A házasság szentségét nászmise keretében szolgáltatjuk ki egymásnak, a Máriaremetei Kisboldogasszony-bazilikában.
        Szentmise után a kertben agapéra várjuk a kedves násznépet a templokertben, itt lesz lehetőség gratulációra is.
      `,
      place: "https://goo.gl/maps/GNw1pXoMkgV8GxVv7",
    },
    "19:00": {
      title: "Vacsora",
      text: `
        A nászmisét követően szűk családi körben vacsorázunk a Munkácsi Mihály Művelődési Házban, Törökbálinton.
      `,
      place: "https://goo.gl/maps/TUFyyoFVEAAmHdJ26",
    },
    "21:00": {
      title: "Mulatság",
      text: `
        A vacsora végeztével barátainkkal és családunkkal megosztva örömünket, hajnalig tartó mulatozásba kezdünk.
        Ne felejtsétek otthon meghívótokat!
      `,
      place: "https://goo.gl/maps/TUFyyoFVEAAmHdJ26",
    },
  };

  faq = [
    {
      q: "Milyen nászajándéknak örülnétek?",
      a: `
        A legnagyobb ajándék ha Veletek ünnepelhetünk! Ha ezen felül szeretnétek közös életünk megkezdéséhez hozzájárulni,
        összeszedtünk egy listát azon apróságokkal, amire szükségünk lesz közös életünk elkezdéséhez, illetve minden
        boríték formátumú hozzájárulást is nagy hálával fogadunk. Ha egyéb ajándékon töritek a fejeteket, Emese barátunk
        (<a href='mailto:emese@africa-wise.com'>emese@africa-wise.com</a>) szívesen segít az ötletek jóváhagyásában.
        A listáról foglalni visszajelzés után tudtok.
      `,
    },
    {
      q: "Hogyan jutok oda és haza?",
      a: `
        <b>Templom</b>: a kertben és a környező utcákban bőségesen van ingyenes parkolóhely. A templomkert autós bejárata
        a Máriaremetei út felől található (<a href='https://goo.gl/maps/SWbwydzL8wE1wqN4A' target='_blank'>utcakép link</a>,
        a behajtani tilos tábla senkit ne riasszon vissza). Tömegközlekedéssel Hűvösvölgy villamosvégállomástól az 57, 157
        és 257-es autóbuszokkkal a Máriaremetei kegytemplom megállóig kell utazni.
        <br>
        <b>Művelődési ház:</b> Hamarosan kiegészíjük a leírást...
      `,
    },
    {
      q: "Mire készüljek, ha kisgyerekkel érkezem?",
      a: `
        Mind a templomba, mind a művelődési házba szeretettel látjuk gyermekeiteket is. A művelődési központban lesz egy
        csendes szoba szoptatásra és altatásra, azonban kérünk titeket, hogy szükség szerint <b>etetőszéket</b> és altatáshoz
        babágyat hozzatok magatokkal, mert a helyszín azt nem biztosít.
      `,
    },
    {
      q: "Dresszkód?",
      a: "Elegáns, amiben szívesen ropod hajnali négyig!",
    },
    {
      q: "Más kérdésem van! Kihez fordulhatok?",
      a: `
        Az esküvővel kapcsolatos kérdéseiteket az <a href='mailto:info@klabi.hu'>info@klabi.hu</a> címen szívesen megválaszoljuk.
        Amennyiben szeretnétek a hátunk mögött intézkedni, Emese tud eligazítani benneteket: 
        <a href='mailto:emese@africa-wise.com'>emese@africa-wise.com</a>.`
    }
  ];
}
